<template>
  <l-map
    style="height:400px;"
    :zoom="zoom"
    :center="latitudeLongitude"
    @update:center="centerUpdate"
  >
    <!-- @update:center="centerUpdate"
    @update:zoom="zoomUpdate" -->
    <l-tile-layer :url="url" :attribution="attribution" />
    <marker-popup :position="marker" :text="markerText" :title="title"></marker-popup>
  </l-map>
</template>

<script>
  import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
  import L from 'leaflet'
  import MarkerPopup from './MarkerPopup';

  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../assets/img/marker-icon-2x.png'),
    iconUrl: require('../../assets/img/marker-icon.png'),
    shadowUrl: require('../../assets/img/marker-shadow.png')
  });

  export default {
    name: 'OSMap',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      MarkerPopup
    },
    data () {
      return {
        zoom: 13,
        url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        // marker: null,
        currentCenter: null
        // currentZoom: 13,
      }
    },
    props: {
      coordinates: Object,
      title: String,
      location: Object
    },
    computed: {
      latitudeLongitude: function () {
        return L.latLng(this.coordinates.latitude, this.coordinates.longitude)
      },
      markerText: function () {
        let result = '<strong>' + this.title + '</strong>'
        if (this.location && this.location['ghwk:wdlocation'][0]) {
          result += ', <a href="' +
            this.location['ghwk:wdlocation'][0]['@id'] +
            '" target="_blank">Wikidata</a>'
        }
        return result
      },
      marker: function () {
        return L.latLng(this.coordinates.latitude, this.coordinates.longitude)
      }
    },
    // created: function () {
      // this.marker = L.latLng(this.coordinates.latitude, this.coordinates.longitude)
    // },
    methods: {
      // zoomUpdate (zoom) {
      //   this.currentZoom = zoom;
      // },
      centerUpdate () {
        return L.latLng(this.coordinates.latitude, this.coordinates.longitude)
      },
      // showLongText () {
      //   this.showParagraph = !this.showParagraph;
      // },
    }
  }
</script>
<style lang="scss" scoped>
  .leaflet-fake-icon-image-2x {
    background-image: url(../../../node_modules/leaflet/dist/images/marker-icon-2x.png);
  }
  .leaflet-fake-icon-shadow {
    background-image: url(../../../node_modules/leaflet/dist/images/marker-shadow.png);
  }
  @import "../../../node_modules/leaflet/dist/leaflet.css";
</style>

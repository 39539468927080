<template>
  <div id="item">
    <!-- loader -->
    <div v-if="!photo" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <div class="col-lg-8">
          <!-- main images -->
          <p v-html="photoTitle"></p>
          <div class="row no-gutters images-row" v-viewer="viewerOptions">
            <div class="col-sm main_image_container" v-if="typeof imageRecto !== 'undefined' && imageRecto !== false && imageRecto.length > 0">
              <img :src="imageRecto[0]['o:thumbnail_urls']['large']"
                :alt="imageAlt(', recto')"
                class="img-fluid">
            </div>
            <div class="col-sm main_image_container" v-if="typeof imageVerso !== 'undefined' && imageVerso !== false && imageVerso.length > 0">
              <img :src="imageVerso[0]['o:thumbnail_urls']['large']"
              :alt="imageAlt(', verso')"
              class="img-fluid">
            </div>
          </div>
          <p class="small mt-2" v-html="photoCaption"></p>
          <!-- item controlls -->
          <div class="row my-4">
            <div class="col" v-if="previousPhotoId !== null">
              <router-link :to="{ name: 'item', params: { id: previousPhotoId }}">
                <button type="button" class="btn btn-primary"><i class="item_nav_left_icon fa fa-angle-left"></i><span class="item_nav_wording">{{translate.global.previous_item[language]}}</span></button>
              </router-link>
            </div>
            <div class="col text-center" v-if="nextPhotoId !== null || previousPhotoId !== null">
              <!-- , hash: '#exhibition_list_item_' + currentPhotoId -->
              <router-link :to="{ name: 'exhibition'}">
                <button type="button" class="btn btn-primary"><i class="item_nav_center_icon fa fa-th-list" aria-hidden="true"></i><span class="item_nav_wording">{{translate.global.all_photos[language]}}</span></button>
              </router-link>
            </div>
            <div class="col text-center" v-else>
              <!-- <router-link :to="-1" v-html="translate.global.exhibition_overview[language]"></router-link> -->
              <button type="button" class="btn btn-primary"
                @click="$router.go(-1)"
                v-html="translate.global['back'][language]"></button>
            </div>
            <div class="col text-right" v-if="nextPhotoId !== null">
              <router-link :to="{ name: 'item', params: { id: nextPhotoId }}">
                <button type="button" class="btn btn-primary"><span class="item_nav_wording">{{translate.global.next_item[language]}}</span><i class="item_nav_right_icon fa fa-angle-right"></i></button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4" id="media-content">
          <!-- comment -->
          <div class="item_ghwk_comment" v-html="ghwkComment"></div>
          <hr />
          <!-- Transkription-Beschriftung -->
          <dl class="item_ghwk_commentcreator" v-if="typeof photo !== 'undefined' &&
            photo !== null && typeof photo['ghwk:commentcreator'] !== 'undefined' &&
            photo['ghwk:commentcreator'].length > 0"
          >
            <dt v-html="translate['metadata_label']['Transkription-Beschriftung'][language]"></dt>
            <dd v-html="ghwkCommentCreator"></dd>
          </dl>
          <hr />
          <!-- location -->
          <dl class="item_ghwk_location" v-if="
            typeof photo !== 'undefined' && photo !== null &&
            typeof photo['ghwk:locationdisplay'] !== 'undefined' &&
            photo['ghwk:locationdisplay'].length > 0"
          >
            <dt v-html="translate['metadata_label']['Ort'][language]"></dt>
            <dd v-if="
              typeof photo['ghwk:locationdisplay'] !== 'undefined' &&
              photo['ghwk:locationdisplay'].length > 0"
              v-html="ghwkLocationdisplay"
            ></dd>
          </dl>
          <!-- map -->
          <!-- v-bind:location="location" -->
          <osmap v-if="latitudeLongitude"
            v-bind:coordinates="latitudeLongitude"
            v-bind:title="ghwkLocationdisplay"></osmap>
          <hr />
          <!-- locations -->
          <dl class="item_ghwk_location" v-if="
            typeof photo !== 'undefined' && photo !== null &&
            typeof photo['ghwk:location'] !== 'undefined' &&
            photo['ghwk:location'].length > 0"
          >
            <dt v-html="translate['metadata_label']['Orte'][language]"></dt>
            <dd v-if="
              typeof photo['ghwk:location'] !== 'undefined' &&
              photo['ghwk:location'].length > 0"
            >
              <template v-for="(photoResource, index) in photo['ghwk:location']">
                <span v-if="index > 0" :key="'photo_resource_spacer_' + index"> | </span>
                <span :key="'photo_resource_' + index">
                  <router-link
                    :to="{ name: 'location', params: { id: photoResource['value_resource_id'] }}"
                    v-html="photoResource.display_title"
                  ></router-link>
                  <small v-if="wikiDataLocation(photoResource['value_resource_id'])">,
                    <a :href="wikiDataLocation(photoResource['value_resource_id'])" target="_blank">
                      ({{ translate['global']['bei'][language] }} Wikidata)
                    </a>
                  </small>
                </span>
              </template>
            </dd>
          </dl>
          <!-- depictedperson -->
          <dl class="item_ghwk_depictedperson" v-if="typeof photo !== 'undefined' &&
            photo !== null && typeof photo['ghwk:depictedperson'] !== 'undefined' &&
            photo['ghwk:depictedperson'].length > 0"
          >
            <dt v-html="translate['metadata_label']['Personen'][language]"></dt>
            <dd>
              <template v-for="(person, index) in photo['ghwk:depictedperson']">
                <span v-if="index > 0" :key="index + '_depictedperson_divider'"> | </span>
                <router-link
                  :key="index + '_depictedperson_link'"
                  :to="{ name: 'person', params: { id: person.value_resource_id }}"
                  v-html="person['display_title']"></router-link>
              </template>
            </dd>
          </dl>
          <!-- Category - Themen -->
          <dl class="item_ghwk_subjectcategory" v-if="typeof photo !== 'undefined' &&
            photo !== null && typeof photo['ghwk:subjectcategory'] !== 'undefined' &&
            photo['ghwk:subjectcategory'].length > 0"
          >
            <dt v-html="translate['metadata_label']['thematische Kategorie'][language]"></dt>
            <dd>
              <template v-for="(category, index) in photo['ghwk:subjectcategory']">
                <span v-if="index > 0" :key="index + '_subjectcategory_devider'"> | </span>
                <router-link
                  :key="index + '_subjectcategory_link'"
                  :to="{ name: 'categoryId', params: { category: encodeURI(category['@value']) }}"
                  v-html="translate['ghwk:subjectcategory'][category['@value']][language]"></router-link>
              </template>
            </dd>
          </dl>
          <!-- date -->
          <dl class="item_ghwk_datedisplay" v-if="typeof photo !== 'undefined' &&
            photo !== null && typeof photo['ghwk:datedisplay'] !== 'undefined' &&
            photo['ghwk:datedisplay'].length > 0"
          >
            <dt v-html="translate['metadata_label']['Datum (Ausgabe)'][language]"></dt>
            <dd>
              <router-link
                v-if="typeof photo['ghwk:date'] !== 'undefined' && typeof photo['ghwk:date'][0] !== 'undefined'"
                :to="{ name: 'date', params: { date: encodeURI(photo['ghwk:date'][0]['@value']) }}"
                v-html="ghwkDatedisplay"></router-link>
              <router-link
                v-else-if="(typeof photo['ghwk:earliest'] !== 'undefined' && typeof photo['ghwk:earliest'][0] !== 'undefined') ||
                (typeof photo['ghwk:latest'] !== 'undefined' && typeof photo['ghwk:latest'][0] !== 'undefined')"
                :to="daterangeLink"
                v-html="ghwkDatedisplay"></router-link>
              <span v-else v-html="ghwkDatedisplay"></span>
            </dd>
          </dl>
          <hr>
          <!-- objectid - inventar nummer -->
          <dl>
            <template v-if="typeof photo !== 'undefined' &&
              photo !== null && typeof photo['ghwk:objectid'] !== 'undefined' &&
              photo['ghwk:objectid'].length > 0"
            >
              <dt v-html="translate['metadata_label']['Inventarnummer'][language]"></dt>
              <dd v-html="photo['ghwk:objectid'][0]['@value']"></dd>
            </template>
            <!-- ordernumber -->
            <template v-if="typeof photo !== 'undefined' &&
              photo !== null && typeof photo['ghwk:ordernumber'] !== 'undefined' &&
              photo['ghwk:ordernumber'].length > 0"
            >
              <dt v-html="translate['metadata_label']['Bestellnummer'][language]"></dt>
              <dd v-html="photo['ghwk:ordernumber'][0]['@value']"></dd>
            </template>
          </dl>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import osmap from '@/components/partials/Map.vue'
import 'viewerjs/dist/viewer.css';

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Item',
  components: {
    'site-header': header,
    osmap
  },
  data () {
    return {
      copyright: ', &copy; <a href="https://creativecommons.org/licenses/by/4.0/deed.de" target="_blank">CC BY 4.0 DE GHWK</a>',
      viewerOptions: {
        "button": true,
        "navbar": false,
        "title": true,
        "toolbar": {
          zoomIn: {
            show: true,
            size: 'large',
          },
          zoomOut: {
            show: true,
            size: 'large',
          },
          oneToOne: {
            show: true,
            size: 'large',
          },
          reset: {
            show: true,
            size: 'large',
          },
          prev: false,
          play: false,
          next: false,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": false,
        "scalable": false,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    (to.params.id) ?
      next(vm => vm.fetchPhoto(Number(to.params.id))) :
      next(vm => vm.fetchPhoto())
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchPhoto(Number(to.params.id))
    next()
  },
  methods: {
    ...mapActions('items', ['fetchPhoto']),
    imageAlt(suffix) {
      let title = '';
      if (typeof this.photo !== 'undefined' && this.photo !== null && this.photo['ghwk:caption'].length > 0) {
        title = this.photo['ghwk:caption'].find(caption => caption['@language'] === this.language)
        if (title !== '') { title = title['@value'] }
      }
      return (title !== '')? title.replace(/(<([^>]+)>)/ig,"") + suffix : ''
    },
    wikiDataLocation(id) {
      let wikidataLink = false
      if (typeof this.locations !== 'undefined' &&
        this.locations !== null &&
        this.locations.length > 0)
      {
        wikidataLink = this.locations.find(item => item['o:id'] === id)
        wikidataLink = (
          typeof wikidataLink !== 'undefined' &&
          typeof wikidataLink['ghwk:wdlocation'] !== 'undefined' &&
          typeof wikidataLink['ghwk:wdlocation'][0] !== 'undefined'
        )? wikidataLink['ghwk:wdlocation'][0]['@id'] : false
      }
      return wikidataLink
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photo: 'items/photo',
      nextPhotoId: 'items/nextPhotoId',
      previousPhotoId: 'items/previousPhotoId',
      currentPhotoId: 'items/currentPhotoId',
      // location: 'items/location',
      media: 'media/media',
      locations: 'locations/locations'
    }),
    photoTitle: function () {
      let title = '';
      if (typeof this.photo !== 'undefined' && this.photo !== null && this.photo['ghwk:caption'].length > 0) {
        title = this.photo['ghwk:caption'].find(caption => caption['@language'] === this.language)
        if (title !== '') { title = title['@value'] }
      }
      return title
    },
    photoCaption: function () {
      let caption = '' // this.translate.metadata_label.Pappe[this.language]
      if (typeof this.photo !== 'undefined' && this.photo !== null) {
        if (this.photo['ghwk:currentnumber'].length > 0) {
          caption += '' + this.photo['ghwk:currentnumber'][0]['@value']
        }
        if (this.photo['ghwk:material'].length > 0) {
          caption += ' - ' + this.translate['ghwk:material'][this.photo['ghwk:material'][0]['@value']][this.language]
        }
        if (this.photo['ghwk:dimensions'].length > 0) {
          caption += ', ' + this.photo['ghwk:dimensions'][0]['@value']
        }
      }
      caption += this.copyright
      return caption
    },
    ghwkComment: function () {
      let comment = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.photo['ghwk:comment'] !== 'undefined' &&
        this.photo['ghwk:comment'].length > 0)
      {
        comment = this.photo['ghwk:comment'].find(caption => caption['@language'] === this.language)
        if (comment !== '') { comment = comment['@value'] }
      }
      return comment
    },
    ghwkCommentCreator: function () {
      let comment = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.photo['ghwk:commentcreator'] !== 'undefined' &&
        this.photo['ghwk:commentcreator'].length > 0)
      {
        comment = this.photo['ghwk:commentcreator'].find(caption => caption['@language'] === this.language)
        if (comment !== '') { comment = comment['@value'] }
      }
      return comment
    },
    ghwkDatedisplay: function () {
      let dates = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.photo['ghwk:datedisplay'] !== 'undefined' &&
        this.photo['ghwk:datedisplay'].length > 0)
      {
        dates = this.photo['ghwk:datedisplay'].find(date => date['@language'] === this.language)
        if (dates !== '') { dates = dates['@value'] }
      }
      return dates.replace(/(<([^>]+)>)/ig,"")
    },
    ghwkLocationdisplay: function () {
      let location = ''
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.photo['ghwk:locationdisplay'] !== 'undefined' &&
        this.photo['ghwk:locationdisplay'].length > 0)
      {
        location = this.photo['ghwk:locationdisplay'].find(prop => prop['@language'] === this.language)
        if (location !== '' && typeof location !== 'undefined') { location = location['@value'] }
      }
      return location.replace(/(<([^>]+)>)/ig,"")
    },
    latitudeLongitude: function () {
      return (typeof this.photo !== 'undefined' && this.photo !== null &&
      typeof this.photo['ghwk:latitude'] !== 'undefined' &&
      this.photo['ghwk:latitude'].length > 0 &&
      typeof this.photo['ghwk:longitude'] !== 'undefined' &&
      this.photo['ghwk:longitude'].length > 0)?
        {
          latitude: this.photo['ghwk:latitude'][0]['@value'],
          longitude: this.photo['ghwk:longitude'][0]['@value']
        } : false
    },
    imageRecto: function () {
      if (typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.media !== 'undefined' && this.media !== null && this.media.length > 0) {
        return this.media.filter(
          file => file['o:item']['o:id'] === this.photo['o:id'] &&
            file['dcterms:title'][0]['@value'] === 'recto')
      } else {
        return false
      }
    },
    imageVerso: function () {
      if(typeof this.photo !== 'undefined' && this.photo !== null &&
        typeof this.media !== 'undefined' && this.media !== null && this.media.length > 0) {
        return this.media.filter(
          file => file['o:item']['o:id'] === this.photo['o:id'] &&
            file['dcterms:title'][0]['@value'] === 'verso')
      } else {
        return false
      }
    },
    daterangeLink: function () {
      let link = { name: 'daterange', params: {}}
      if (typeof this.photo !== 'undefined' && this.photo !== null) {
        if (typeof this.photo['ghwk:earliest'] !== 'undefined' && typeof this.photo['ghwk:earliest'][0] !== 'undefined') {
          link.params.earliest = encodeURI(this.photo['ghwk:earliest'][0]['@value'])
        }
        if (typeof this.photo['ghwk:latest'] !== 'undefined' && typeof this.photo['ghwk:latest'][0] !== 'undefined') {
          link.params.latest = encodeURI(this.photo['ghwk:latest'][0]['@value'])
        }
      }
      return link
    }
  }
}
</script>
<style lang="scss">
.viewer-toolbar > ul > .viewer-large {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}
.main_image_container {
  background-color: #828186;
  img:hover {
    cursor: pointer;
  }
}
.viewer-title {
  margin-bottom: 18px;
  color: #ffffff;
  text-shadow: 1px 1px 1px black;
  line-height: 1.25;
}
</style>
